import React from 'react';
import FirstPage from './first_page_declaration';
import SecondPage from './second_page_declaration';
import ThirdPage from './third_page_declaration';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { config } from 'services/config';
import { startRobot } from 'services/declarationService';
import { replaceDeclarationCSV } from 'services/declarationService';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from 'components/Card/Card.js';
import { DropzoneArea } from 'material-ui-dropzone';
import Datetime from 'react-datetime';
import MailOutline from '@material-ui/icons/MailOutline';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Fill the fields and upload CSV'];
}

function getStepContent(
  step,
  handleNext,
  handleBack,
  dataFields,
  setDataFields,
  date,
  setDate,
  files,
  setFiles,
  path,
  setPath,
  declarationId,
  setDeclarationId
) {
  switch (step) {
    case 0:
      return (
        <FirstPage
          handleNext={handleNext}
          handleBack={handleBack}
          data={dataFields}
          setData={setDataFields}
          date={date}
          setDate={setDate}
          files={files}
          setFiles={setFiles}
          path={path}
          setPath={setPath}
          declarationId={declarationId}
          setDeclarationId={setDeclarationId}
        />
      );
    case 1:
      return (
        <SecondPage
          handleNext={handleNext}
          handleBack={handleBack}
          path={path}
          setPath={setPath}
        />
      );
    case 2:
      return (
        <ThirdPage
          handleNext={handleNext}
          handleBack={handleBack}
          declarationId={declarationId}
        />
      );
    default:
      return 'Unknown page!';
  }
}

export default function WizardDeclarationTemplate() {
  const history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  const [date, setDate] = React.useState(null);
  const [files, setFiles] = React.useState([]);
  const [data, setData] = React.useState({
    LRN: '',
  });
  const [path, setPath] = React.useState();
  const [declarationId, setDeclarationId] = React.useState();

  const [open, setOpen] = React.useState(false);
  const [activeId, setActiveId] = React.useState();
  const [activeDate, setActiveDate] = React.useState();
  const [activeFiles, setActiveFiles] = React.useState();
  const [error, setError] = React.useState(false);
  const [errorDescription, setErrorDescription] = React.useState('');
  const [buttonLoading, setButtonLoading] = React.useState(false);

  const handleUpload = (files) => {
    setActiveFiles(files);
  };

  const handleDate = (newDate) => {
    setActiveDate(newDate);
  };

  const handleSubmit = (val) => {
    if (!activeFiles[0]) {
      setError(true);
      setErrorDescription('Please upload CSV!');
      setTimeout(() => {
        setError(false);
        setErrorDescription('');
      }, [3000]);
    } else {
      setButtonLoading(true);
      replaceDeclarationCSV(activeFiles[0], activeDate, activeId).then(
        (snapshot) => {
          if (snapshot.status < 300) {
            snapshot.json().then((response) => {
              setButtonLoading(false);
              handleClose();
              history.push('/admin/listDeclaration');
            });
          } else {
            setButtonLoading(false);
            setError(true);
            setErrorDescription(
              'Something went wrong with your CSV, please try again!'
            );
            setTimeout(() => {
              setError(false);
              setErrorDescription('');
            }, [3000]);
          }
        }
      );
    }
  };

  const handleClickOpen = (id) => {
    setActiveId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setActiveId();
    setOpen(false);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setPath();
    setData({
      LRN: '',
    });
    setFiles();
    setDate();
    setActiveStep(0);
  };

  function startRobotF(id) {
    startRobot(id).then((response) => {
      if (response.status < 300) {
        history.push('/admin/listDeclaration');
      } else {
        alert('error');
      }
    });
  }

  function installCsv() {
    const url = config.apiUrl + path;
    window.open(url, 'Download');
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Change CSV</DialogTitle>
        <DialogContent>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Upload new CSV</h4>
            </CardHeader>
            <CardBody>
              <form>
                <DropzoneArea
                  className={classes.fileUpload}
                  showPreviews={true}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  filesLimit={1}
                  previewGridProps={{
                    container: { spacing: 1, direction: 'row' },
                  }}
                  previewText="Selected files"
                  onChange={handleUpload}
                />
              </form>
            </CardBody>
          </Card>
          {error ? <p style={{ color: 'red' }}>{errorDescription}</p> : null}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={handleSubmit}
              color="primary"
              disabled={buttonLoading}
            >
              Change CSV
            </Button>
            {buttonLoading && <CircularProgress size={24} />}
          </div>
        </DialogActions>
      </Dialog>
      <div>
        {activeStep === steps.length ? (
          <div>
            <div
              style={{
                display: 'inline-flex',
                flexDirection: 'column',
                marginTop: 50,
                marginBottom: 50,
              }}
            >
              <div
                style={{
                  backgroundColor: 'rgb(0,162,183)',
                  display: 'inline-flex',
                  alignItems: 'center',
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 9,
                  paddingBottom: 9,
                  borderRadius: 4,
                  cursor: 'pointer',
                }}
                onClick={() => handleClickOpen(declarationId)}
              >
                <span
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    letterSpacing: 0.75,
                  }}
                >
                  CHANGE CSV
                </span>
              </div>
              <div
                style={{
                  backgroundColor: 'orange',
                  display: 'inline-flex',
                  alignItems: 'center',
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 9,
                  paddingBottom: 9,
                  borderRadius: 4,
                  cursor: 'pointer',
                  marginTop: 10,
                  marginBottom: 10,
                }}
                onClick={() => installCsv()}
              >
                <span
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    letterSpacing: 0.75,
                  }}
                >
                  DOWNLOAD CSV
                </span>
              </div>
              <div
                style={{
                  backgroundColor: '#35bd5b',
                  display: 'inline-flex',
                  alignItems: 'center',
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 9,
                  paddingBottom: 9,
                  borderRadius: 4,
                  cursor: 'pointer',
                }}
                onClick={() => startRobotF(declarationId)}
              >
                <span
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    letterSpacing: 0.75,
                  }}
                >
                  START ROBOT
                </span>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignSelf: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                onClick={handleReset}
                variant="contained"
                style={{
                  width: '49%',
                  padding: 10,
                  backgroundColor: 'grey',
                  color: 'white',
                  fontWeight: '700',
                }}
              >
                NEW DECLARATION
              </Button>
              <Button
                onClick={() => history.push('/admin/listDeclaration')}
                variant="contained"
                style={{
                  width: '49%',
                  padding: 10,
                  backgroundColor: 'rgb(0, 153, 159)',
                  color: 'white',
                  fontWeight: '700',
                }}
              >
                GO TO LIST OF DECLARATIONS
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(
                activeStep,
                handleNext,
                handleBack,
                data,
                setData,
                date,
                setDate,
                files,
                setFiles,
                path,
                setPath,
                declarationId,
                setDeclarationId
              )}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}
