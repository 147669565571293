import { config, authHeaders } from './config';

export function getDeclaration(page) {
  const userURL = `${config.apiUrl}/users/me`;
  const accountId = localStorage.getItem('RAVEN_CHOSEN_ACCOUNT');

  let posting = fetch(userURL, {
    method: 'GET',
    headers: authHeaders(),
  }).then((a) => {
    if (a.status == 401) {
      window.location.href = '/auth/login';
    }
    return a.text().then(() => {
      const URL = `${config.apiUrl}/declarations/?page=${page}&account.id=${accountId}`;
      return fetch(URL, {
        method: 'GET',
        headers: authHeaders(),
      }).then((a) => {
        return a.json();
      });
    });
  });

  let completeData = posting.then((a) => {
    return a;
  });

  return completeData;
}
