import React from 'react';
import { TextFormField } from '../CustomComponents/TextFormField';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { Button } from '@material-ui/core';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import { DropzoneArea } from 'material-ui-dropzone';
import MailOutline from '@material-ui/icons/MailOutline';
import { makeStyles } from '@material-ui/core/styles';
import Datetime from 'react-datetime';
import { newDeclaration } from '../../services/declarationService.js';
import FormControl from '@material-ui/core/FormControl';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import BarLoader from 'react-spinners/BarLoader';
import { css } from '@emotion/react';
import Select from 'react-select';
import { getTemplates } from 'services/template';
import { getTemplatesByID } from 'services/template';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import { TextField } from '@material-ui/core';
import { getCurrentUser } from 'services/template';

const useStyles = makeStyles(styles);

const override = css`
  display: block;
  margin: 0 auto;
  border-color: gray;
`;

const formObject = [
  {
    name: 'LRN',
    label: 'LRN',
    disabled: false,
    component: TextFormField,
  },
];

const schema = yup.object({
  LRN: yup.string().required().min(3),
});

const today = moment();

export default function FirstPage(props) {
  const [error, setError] = React.useState(false);
  const [errorDescription, setErrorDescription] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [loadingDocs, setLoadingDocs] = React.useState(false);
  const [template, setTemplate] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState();
  const [data, setData] = React.useState([]);
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: false,
  });
  const [dataTemplate, setDataTemplate] = React.useState({});
  const [field, setField] = React.useState({
    refDoc: '',
    tipDoc: '',
    dataDoc: moment(today).format('YYYY-MM-DD'),
    limbaDoc: '',
  });
  const columns = [
    {
      title: 'TIP DOCUMENT',
      render: (text) => <p>{text['TIP DOCUMENT']}</p>,
    },
    {
      title: 'REF DOCUMENT',
      render: (text) => {
        if (text['REF DOCUMENT EDITABIL'] === 'True') {
          return (
            <TextField
              style={{ width: '100%' }}
              variant="outlined"
              label="REF DOC"
              value={text['REF DOCUMENT']}
              name="refDoc"
              onChange={(event) => changeDataRef(text['TIP DOCUMENT'], event)}
            />
          );
        } else {
          return (
            <TextField
              style={{ width: '100%' }}
              variant="outlined"
              label="REF DOC"
              value={text['REF DOCUMENT']}
              disabled={true}
              name="refDoc"
              onChange={changeField}
            />
          );
        }
      },
    },
    {
      title: 'REF DOCUMENT EDITABIL',
      render: (text) => <p>{text['REF DOCUMENT EDITABIL']}</p>,
    },
    {
      title: 'DATA',
      render: (text) => <p>{text['DATA']}</p>,
    },
    {
      title: 'LIMBA',
      render: (text) => <p>{text['LIMBA']}</p>,
    },
    {
      title: 'ACTION',
      render: (text) => (
        <divd
          style={{
            backgroundColor: '#ed616d',
            display: 'inline-flex',
            alignItems: 'center',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 9,
            paddingBottom: 9,
            borderRadius: 4,
            cursor: 'pointer',
          }}
          onClick={() => removeRow(text['TIP DOCUMENT'])}
        >
          <span
            style={{
              color: 'white',
              fontWeight: 'bold',
              letterSpacing: 0.75,
            }}
          >
            DELETE
          </span>
        </divd>
      ),
    },
  ];

  function changeDataRef(tipDoc, event) {
    const copy = [...data];
    const index = copy.findIndex((x) => x['TIP DOCUMENT'] === tipDoc);

    copy[index]['REF DOCUMENT'] = event.target.value;
    setData(copy);
  }

  function removeRow(tipDoc) {
    const newList = data.filter((item) => item['TIP DOCUMENT'] !== tipDoc);

    setData(newList);
  }

  React.useEffect(() => {
    getCurrentUser().then((snap) => {
      getTemplates(snap).then((response) => {
        const options = response['hydra:member'].map((val) => {
          const body = {
            value: val.id,
            label: val.templateDenumire,
          };

          return body;
        });

        setTemplate(options);
        if (options.length > 0) {
          setSelectedTemplate(options[0]);
        }
      });
    });
  }, []);

  React.useEffect(() => {
    if (selectedTemplate) {
      setLoadingDocs(true);
      getTemplatesByID(selectedTemplate.value)
        .then((response) => {
          setDataTemplate(response);
          setData(response.documenteAtasate);
        })
        .then(() => {
          setLoadingDocs(false);
        });
    }
  }, [selectedTemplate]);

  const handleDate = (newDate) => {
    props.setDate(newDate);
  };

  const handleUpload = (files) => {
    props.setFiles(files);
  };

  const handleSubmit = (val) => {
    if (!props.files[0]) {
      setError(true);
      setErrorDescription('Please upload CSV!');
      setTimeout(() => {
        setError(false);
        setErrorDescription('');
      }, [3000]);
    } else {
      setLoading(true);
      props.setData(val);

      let columns = [
        'Type',
        'Material description (EN)',
        'Comm./imp. code no.',
        'Billed Quantity',
        'Net weight',
        'Gross weight',
        'Billing qty in SKU',
        'Billing Type',
        'Account Of Shipto',
        'End.Net Total(EUR)',
        'Incoterms',
        'Incoterms (Part 2)',
        'Net Total',
        'Document Currency',
        'Sales unit',
        'Plant',
        'Shipping Point',
        'Shipping Point Description',
        'Material Description',
        'Billed qty.',
        'Material Group Desc.',
        'Product Hier3',
        'Billing Document',
        'Billing Date',
        'Ship to Party Name',
        'Street',
        'Postal Code',
        'City',
        'Ship To Country',
        'Truck Number',
        'Material',
        'Name 1(EUR)',
        'Product Hier1',
        'Product Hier2',
        'Product Hier4',
      ];

      newDeclaration(
        props.files[0],
        columns,
        val.LRN,
        selectedTemplate.value,
        data
      ).then((snapshot) => {
        if (snapshot.status < 300) {
          snapshot
            .json()
            .then((response) => {
              props.setDeclarationId(response.declaration.id);
              props.setPath(response.filePath);
              props.handleNext();
            })
            .then(() => {
              setLoading(false);
            });
        } else {
          setLoading(false);
          setError(true);
          setErrorDescription(
            'Something went wrong with your CSV, please try again!'
          );
          setTimeout(() => {
            setError(false);
            setErrorDescription('');
          }, [3000]);
        }
      });
    }
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const changeField = (event) => {
    setField({ ...field, [event.target.name]: event.target.value });
  };

  function resetFields() {
    setField({
      refDoc: '',
      tipDoc: '',
      dataDoc: moment(today).format('YYYY-MM-DD'),
      limbaDoc: '',
    });
  }

  function addNewRow() {
    if (field.tipDoc && field.refDoc && field.limbaDoc) {
      const object = {
        'TIP DOCUMENT': field.tipDoc,
        'REF DOCUMENT': field.refDoc,
        'REF DOCUMENT EDITABIL': state.checkedA ? 'True' : 'False',
        DATA: state.checkedB ? 'Data din factura' : field.dataDoc,
        LIMBA: field.limbaDoc,
      };

      setData([...data, object]);
      setField({
        refDoc: '',
        tipDoc: '',
        dataDoc: moment(today).format('YYYY-MM-DD'),
        limbaDoc: '',
      });
    } else {
      setError(true);
      setErrorDescription('Completeaza toate campurile!');
      setTimeout(() => {
        setError(false);
        setErrorDescription('');
      }, [3000]);
    }
  }

  const classes = useStyles();

  return (
    <div>
      {loading ? (
        <div style={{ marginTop: 50 }}>
          <BarLoader
            color={'#787878'}
            loading={true}
            css={override}
            size={150}
          />
        </div>
      ) : (
        <Formik
          validationSchema={schema}
          initialValues={{
            LRN: props.data.LRN,
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {() => (
            <div>
              <div
                style={{
                  marginTop: 20,
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-end',
                }}
              >
                <Select
                  placeholder={'Nume template'}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                    }),
                    control: (provided) => ({
                      ...provided,
                      width: 400,
                      height: 56,
                      backgroundColor: '#eeeeee',
                      border: '1px solid rgba(0, 0, 0, 0.23)',
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      neutral50: 'rgb(102,102,102)',
                    },
                  })}
                  options={template}
                  value={selectedTemplate}
                  onChange={(option) => setSelectedTemplate(option)}
                />
              </div>
              <Card style={{ marginTop: 70 }}>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <MailOutline />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Upload CSV</h4>
                </CardHeader>
                <CardBody>
                  <form>
                    <DropzoneArea
                      className={classes.fileUpload}
                      showPreviews={true}
                      showPreviewsInDropzone={false}
                      useChipsForPreview
                      filesLimit={1}
                      previewGridProps={{
                        container: { spacing: 1, direction: 'row' },
                      }}
                      previewText="Selected files"
                      onChange={handleUpload}
                    />
                  </form>
                </CardBody>
              </Card>

              {error ? (
                <p style={{ marginTop: 30, color: 'red' }}>
                  {errorDescription}
                </p>
              ) : null}

              <Form>
                <Card>
                  <Table
                    className="table-striped"
                    style={{ overflowX: 'auto' }}
                    columns={columns}
                    bordered
                    rowKey={(snap) => {
                      return snap['TIP DOCUMENT'];
                    }}
                    loading={loadingDocs}
                    pagination={false}
                    dataSource={data}
                  />
                </Card>
                <Card>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField
                      style={{ margin: 10, width: '33%' }}
                      variant="outlined"
                      label="TIP DOC."
                      name="tipDoc"
                      value={field.tipDoc}
                      onChange={changeField}
                    />
                    <TextField
                      style={{ margin: 10, width: '33%' }}
                      variant="outlined"
                      label="REF. DOC."
                      name="refDoc"
                      value={field.refDoc}
                      onChange={changeField}
                    />

                    <TextField
                      style={{ margin: 10, width: '33%' }}
                      variant="outlined"
                      label="LIMBA"
                      value={field.limbaDoc}
                      name="limbaDoc"
                      onChange={changeField}
                    />
                  </div>
                  {!state.checkedB && (
                    <div>
                      <TextField
                        style={{ marginLeft: 10, marginTop: 10, width: '33%' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="date"
                        variant="outlined"
                        label="DATA"
                        value={field.dataDoc}
                        name="dataDoc"
                        onChange={changeField}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: 15,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.checkedA}
                          onChange={handleChange}
                          name="checkedA"
                          color="primary"
                        />
                      }
                      label="Referinta documentului poate sa fie editabil"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.checkedB}
                          onChange={handleChange}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Data va veni din factura"
                    />
                  </div>

                  <div style={{ margin: 15 }}>
                    {error ? (
                      <p style={{ color: 'red' }}>{errorDescription}</p>
                    ) : null}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      margin: 15,
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        width: '20%',
                        padding: 10,
                        color: 'grey',
                        fontWeight: '700',
                        marginRight: 15,
                      }}
                      onClick={resetFields}
                    >
                      RESET
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        width: '20%',
                        padding: 10,
                        backgroundColor: 'rgb(0, 153, 159)',
                        color: 'white',
                        fontWeight: '700',
                      }}
                      onClick={addNewRow}
                    >
                      ADD NEW ROW
                    </Button>
                  </div>
                </Card>
                {formObject.map((snapshot, key) => {
                  return <Field {...snapshot} key={key} />;
                })}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignSelf: 'center',
                    alignItems: 'center',
                    marginTop: 60,
                  }}
                >
                  <Button
                    disabled={true}
                    style={{
                      width: '49%',
                      padding: 10,
                      color: 'grey',
                      fontWeight: '700',
                    }}
                  >
                    BACK
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      width: '49%',
                      padding: 10,
                      backgroundColor: 'rgb(0, 153, 159)',
                      color: 'white',
                      fontWeight: '700',
                    }}
                    type="submit"
                  >
                    NEXT
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
}
