import React from 'react';
import { Button } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { config } from 'services/config';

export default function SecondPage(props) {
  function installCsv() {
    const url = config.apiUrl + props.path;
    window.open(url, 'Download');
  }

  return (
    <div style={{}}>
      <div
        style={{
          margin: '0 auto',
          height: 150,
          width: 500,
          display: 'flex',
          marginTop: 30,
        }}
      >
        <Button
          style={{
            width: '100%',
            padding: 10,
            backgroundColor: 'white',
            color: 'grey',
            fontWeight: '700',
            borderRadius: 10,
          }}
          startIcon={<DescriptionIcon />}
          onClick={() => installCsv()}
        >
          Download new CSV
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignSelf: 'center',
          alignItems: 'center',
          marginTop: 30,
        }}
      >
        <Button
          variant="contained"
          style={{
            width: '49%',
            padding: 10,
            color: 'grey',
            fontWeight: '700',
          }}
          onClick={() => props.handleBack()}
        >
          BACK
        </Button>
        <Button
          variant="contained"
          style={{
            width: '49%',
            padding: 10,
            backgroundColor: 'rgb(0, 153, 159)',
            color: 'white',
            fontWeight: '700',
          }}
          onClick={() => props.handleNext()}
        >
          NEXT
        </Button>
      </div>
    </div>
  );
}
