import { config, authHeaders } from './config';

export function getAccounts() {
  const userURL = `${config.apiUrl}/users/me`;

  let posting = fetch(userURL, {
    method: 'GET',
    headers: authHeaders(),
  }).then((a) => {
    if (a.status == 401) {
      window.location.href = '/auth/login';
    }
    return a.json().then((text) => {
      const URL = `${config.apiUrl}/accounts`;
      return fetch(URL, {
        method: 'GET',
        headers: authHeaders(),
      }).then((a) => {
        return a.json();
      });
    });
  });

  let completeData = posting.then((a) => {
    return a;
  });

  return completeData;
}

export function updateUserAccount(activeClient) {
  const userURL = `${config.apiUrl}/users/me`;

  let completeData = fetch(userURL, {
    method: 'GET',
    headers: authHeaders(),
  }).then((a) => {
    if (a.status == 401) {
      window.location.href = '/auth/login';
    }
    return a.text().then((text) => {
      let userId = text;

      const URL = config.apiUrl + `/users/${userId}`;
      return fetch(URL, {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('authToken'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          activeChildren: `accounts/${activeClient}`,
        }),
      }).then((a) => {
        return a.json();
      });
    });
  });

  let snap = completeData.then((a) => {
    return a;
  });

  return snap;
}

export function getActiveAccount(accountId) {
  const userURL = `${config.apiUrl}/users/me`;

  let completeData = fetch(userURL, {
    method: 'GET',
    headers: authHeaders(),
  }).then((a) => {
    if (a.status == 401) {
      window.location.href = '/auth/login';
    }
    return a.text().then((text) => {
      const URL = config.apiUrl + `/accounts/${accountId}`;
      return fetch(URL, {
        method: 'GET',
        headers: authHeaders(),
      }).then((a) => {
        return a.json();
      });
    });
  });

  let snap = completeData.then((a) => {
    return a;
  });

  return snap;
}
