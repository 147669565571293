import ErrorPage from 'views/Pages/ErrorPage.js';
import ListDeclaration from 'views/ListDeclaration/ListDeclaration.js';
import LoginPage from 'views/Pages/LoginPage.js';
import ForgotPassword from 'components/login/forgotPassword.js';

import WizardDeclaration from 'views/WizardNewDeclaration';
import WizardTemplate from 'views/WizardTemplate';
import WizardDeclarationTemplate from 'views/WizardNewDeclarationTemplate';
import ReportingListDeclaration from 'views/ReportingListDeclaration/reportingListDeclaration';
import GeneratedRaports from 'views/GeneratedRaports/GeneratedRaports';
import DailyReporting from 'views/DailyReporting/DailyReporting';

const ROLE_PLEXUS_REPORTING = [
  {
    path: '/listReporting',
    name: 'Lista Rapoarte',
    mini: 'L.R.',
    component: ReportingListDeclaration,
    layout: '/admin',
  },
  {
    path: '/generatedReports',
    name: 'Rapoarte Generate',
    mini: 'R.G.',
    component: GeneratedRaports,
    layout: '/admin',
  },
];

const ROLE_DAILY_REPORTING = [
  {
    path: '/dailyReports',
    name: 'Rapoarte Zilnice',
    mini: 'R.Z.',
    component: DailyReporting,
    layout: '/admin',
  },
];

const ROLE_CREATE_DECLARATION = [
  {
    path: '/listDeclaration',
    name: 'Lista Declaratii',
    mini: 'L.D.',
    component: ListDeclaration,
    layout: '/admin',
  },
  {
    path: '/wizardDeclaration',
    name: 'Declaratie Noua',
    mini: 'D.N.',
    component: WizardDeclaration,
    layout: '/admin',
  },
];

const STANDARD = [
  {
    path: '/error-page',
    name: 'Error Page',
    mini: 'E',
    component: ErrorPage,
    layout: '/',
  },
  {
    collapse: true,
    name: 'Pages',
    state: 'pageCollapse',
    redirect: '/login/',
    views: [
      {
        path: '/login/',
        name: 'Login',
        miniName: 'L',
        component: LoginPage,
        layout: '/auth',
      },
      {
        path: '/forgotPassword/',
        name: 'Forgot password',
        miniName: 'L',
        component: ForgotPassword,
        layout: '/auth',
      },
    ],
  },
];

export default function manage_routes(ROLE) {
  let routes_list = [...STANDARD];

  if (ROLE[0] !== '') {
    ROLE = ROLE.split(',');
  }

  ROLE.forEach((value) => {
    if (value === 'ROLE_PLEXUS_REPORTING') {
      routes_list = routes_list.concat(ROLE_PLEXUS_REPORTING);
    } else if (value === 'ROLE_DAILY_REPORTING') {
      routes_list = routes_list.concat(ROLE_DAILY_REPORTING);
    } else if (value === 'ROLE_CREATE_DECLARATION') {
      routes_list = routes_list.concat(ROLE_CREATE_DECLARATION);
    }
  });

  return routes_list;
}
