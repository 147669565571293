import { config, authHeaders } from './config';

export function getTaxCodeML(data) {
  const userURL = `${config.apiUrl}/users/me`;

  let completeData = fetch(userURL, {
    method: 'GET',
    headers: authHeaders(),
  }).then((a) => {
    if (a.status == 401) {
      window.location.href = '/auth/login';
    }
    return a.text().then(() => {
      const URL = `${config.apiUrl}/get_tax_code`;
      return fetch(URL, {
        method: 'POST',
        headers: authHeaders(),
        body: JSON.stringify(data),
      }).then((a) => {
        return a.json();
      });
    });
  });

  let snap = completeData.then((a) => {
    return a;
  });

  return snap;
}

