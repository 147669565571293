import { config, authHeaders } from './config';

export function getReportingDeclaration(page) {
  const userURL = `${config.apiUrl}/users/me`;

  let posting = fetch(userURL, {
    method: 'GET',
    headers: authHeaders(),
  }).then((a) => {
    if (a.status === 401) {
      window.location.href = '/auth/login';
    }
    return a.text().then((text) => {
      const accountId = localStorage.getItem('RAVEN_CHOSEN_ACCOUNT');

      const URL = `${config.apiUrl}/reporting_declarations/?page=${page}&account.id=${accountId}`;
      return fetch(URL, {
        method: 'GET',
        headers: authHeaders(),
      }).then((a) => {
        return a.json();
      });
    });
  });

  let completeData = posting.then((a) => {
    return a;
  });

  return completeData;
}

export function getGeneratedReportingDeclaration(page) {
  const userURL = `${config.apiUrl}/users/me`;

  let posting = fetch(userURL, {
    method: 'GET',
    headers: authHeaders(),
  }).then((a) => {
    if (a.status === 401) {
      window.location.href = '/auth/login';
    }
    return a.text().then((text) => {
      const accountId = localStorage.getItem('RAVEN_CHOSEN_ACCOUNT');
      const URL = `${config.apiUrl}/generated_reporting_declarations/?page=${page}&account.id=${accountId}`;
      return fetch(URL, {
        method: 'GET',
        headers: authHeaders(),
      }).then((a) => {
        return a.json();
      });
    });
  });

  let completeData = posting.then((a) => {
    return a;
  });

  return completeData;
}

export function getDailyReportingDeclaration(page) {
  const userURL = `${config.apiUrl}/users/me`;

  let posting = fetch(userURL, {
    method: 'GET',
    headers: authHeaders(),
  }).then((a) => {
    if (a.status === 401) {
      window.location.href = '/auth/login';
    }
    return a.text().then((text) => {
      const accountId = localStorage.getItem('RAVEN_CHOSEN_ACCOUNT');
      const URL = `${config.apiUrl}/daily_reporting_declarations/?page=${page}&account.id=${accountId}`;
      return fetch(URL, {
        method: 'GET',
        headers: authHeaders(),
      }).then((a) => {
        return a.json();
      });
    });
  });

  let completeData = posting.then((a) => {
    return a;
  });

  return completeData;
}

export function addGeneratedReportingDeclaration(day, path) {
  const userURL = `${config.apiUrl}/users/me`;

  let completeData = fetch(userURL, {
    method: 'GET',
    headers: authHeaders(),
  }).then((a) => {
    if (a.status === 401) {
      window.location.href = '/auth/login';
    }
    return a.text().then((text) => {
      let userId = text;
      const accountId = localStorage.getItem('RAVEN_CHOSEN_ACCOUNT');
      const URL = config.apiUrl + '/generated_reporting_declarations';
      return fetch(URL, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('authToken'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          day: day,
          path: path,
          users: `users/${userId}`,
          account: `accounts/${accountId}`,
        }),
      });
    });
  });

  return completeData;
}

export function addReportinDeclaration(lrn, date, files) {
  const userURL = `${config.apiUrl}/users/me`;

  let completeData = fetch(userURL, {
    method: 'GET',
    headers: authHeaders(),
  }).then((a) => {
    if (a.status === 401) {
      window.location.href = '/auth/login';
    }
    return a.text().then((text) => {
      const accountId = localStorage.getItem('RAVEN_CHOSEN_ACCOUNT');
      let data = new FormData();
      data.append('file', files);
      data.append('lrn', lrn);
      data.append('date', date);
      data.append('status', 'not started');
      data.append('accountId', accountId);

      const URL = config.apiUrl + '/reporting_declarations';
      return fetch(URL, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('authToken'),
        },
        body: data,
      });
    });
  });

  return completeData;
}

export function updateCSVReportinDeclaration(files, id) {
  const userURL = `${config.apiUrl}/users/me`;

  let completeData = fetch(userURL, {
    method: 'GET',
    headers: authHeaders(),
  }).then((a) => {
    if (a.status === 401) {
      window.location.href = '/auth/login';
    }

    let data = new FormData();

    data.append('file', files);
    data.append('reportingDeclarationID', id);

    const URL = config.apiUrl + `/replaceReportingDeclarationFile`;
    return fetch(URL, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('authToken'),
      },
      body: data,
    });
  });

  return completeData;
}

export function startReportingRobot(reportingDeclarationId) {
  const URL = config.apiUrl + '/queueJob';
  const accountId = localStorage.getItem('RAVEN_CHOSEN_ACCOUNT');
  return fetch(URL, {
    method: 'POST',
    headers: authHeaders(),
    body: JSON.stringify({
      declarationId: 0,
      reportingDeclarationId: reportingDeclarationId,
      type: 'report',
      accountId: accountId,
    }),
  });
}

export function generate_csv(lrn, selectedDay) {
  const URL = config.apiUrl + '/generate_csv';

  return fetch(URL, {
    method: 'POST',
    headers: authHeaders(),
    body: JSON.stringify({
      lrn: lrn,
      selectedDay: selectedDay,
    }),
  });
}

export function deleteReportingDeclarationService(id) {
  const URL = config.apiUrl + `/reporting_declarations/${id}`;

  return fetch(URL, {
    method: 'DELETE',
    headers: authHeaders(),
  });
}
