import 'antd/dist/antd.css';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Table } from 'antd';
import Card from 'components/Card/Card.js';
import { Box } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer.js';
import { format, eachDayOfInterval } from 'date-fns';
import React from 'react';
import { config } from 'services/config.js';
import Button from 'components/CustomButtons/Button.js';
import { getDailyReportingDeclaration } from 'services/reportDeclarationTable.js';

import { itemRender } from '../../paginationfunction';
import { startDailyRobot } from 'services/declarationService';
import { generateDailyPath } from 'services/declarationService';
import { restartRobotDaily } from 'services/declarationService';
import CustomCalendar from 'components/CustomCalendar/Calendar';

export default function DailyReporting() {
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [generatedButton, setGeneratedButton] = React.useState(false);
  const [rangeImport, setRangeImport] = React.useState(Date.now());
  const [rangeExport, setRangeExport] = React.useState(Date.now());
  const [restartRobotButton, setRestartRobotButton] = React.useState(false);

  const columns = [
    {
      title: 'ID',
      render: (text) => <p>{text['id']}</p>,
    },
    {
      title: 'DAY',
      render: (text) => (
        <p>{text['days'][text['days'].length - 1] + '-' + text['days'][0]}</p>
      ),
    },
    {
      title: 'TYPE',
      render: (text) => <p>{text['type']}</p>,
    },
    {
      title: 'STATUS',
      render: (text) => showStatus(text),
    },
    {
      title: 'ACTION',
      render: (text) => generateCSV(text),
    },
  ];

  function showStatus(data) {
    if (data['status'] === 'done') {
      return (
        <p
          style={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: 0.75,
            color: '#35bd5b',
          }}
        >
          {'Date din dvi preluate cu succes'}
        </p>
      );
    } else if (data['status'] === 'on progress') {
      return (
        <p
          style={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: 0.75,
            color: 'rgb(0,162,183)',
          }}
        >
          {'On progress'}
        </p>
      );
    } else if (data['status'] === 'error') {
      return (
        <p
          style={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: 0.75,
            color: '#c94951',
          }}
        >
          {'Error'}
        </p>
      );
    }
  }

  function generateCSV(data) {
    if (data['status'] === 'done') {
      return (
        <Button
          style={{
            backgroundColor: 'orange',
            display: 'inline-flex',
            alignItems: 'center',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 9,
            paddingBottom: 9,
            borderRadius: 4,
            cursor: 'pointer',
            marginTop: 5,
            marginBottom: 5,
          }}
          onClick={() =>
            !generatedButton &&
            downloadCsv(data['path'], data['id'], data['type'])
          }
        >
          {generatedButton ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            <span
              style={{
                color: 'white',
                fontWeight: 'bold',
                letterSpacing: 0.75,
              }}
            >
              DOWNLOAD CSV
            </span>
          )}
        </Button>
      );
    } else if (data['status'] === 'error') {
      return (
        <Button
          style={{
            backgroundColor: '#ba1407',
            display: 'inline-flex',
            alignItems: 'center',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 9,
            paddingBottom: 9,
            borderRadius: 4,
            cursor: 'pointer',
            marginTop: 5,
            marginBottom: 5,
          }}
          onClick={() =>
            !restartRobotButton && restartRobot(data['id'], data['type'])
          }
        >
          {restartRobotButton ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            <span
              style={{
                color: 'white',
                fontWeight: 'bold',
                letterSpacing: 0.75,
              }}
            >
              RESTART ROBOT
            </span>
          )}
        </Button>
      );
    }
  }

  async function restartRobot(id, type) {
    setRestartRobotButton(true);
    const req_data = await restartRobotDaily(id, type);
    const json_req_data = await req_data.json();
    if (json_req_data.status === 'OK') {
      setCount((count) => count + 1);
    } else {
      alert('Something went wrong!');
    }
    setRestartRobotButton(false);
  }

  async function downloadCsv(url, id, type) {
    setGeneratedButton(true);
    if (url) {
      const customUrl = config.apiUrl + url;
      window.open(customUrl, 'Download');
      setGeneratedButton(false);
    } else {
      const req_data = await generateDailyPath(id, type);
      const json_req_data = await req_data.json();
      if (json_req_data.status === 'OK') {
        const customUrl = config.apiUrl + json_req_data.path;
        window.open(customUrl, 'Download');
        setCount((count) => count + 1);
      } else {
        alert('Something went wrong!');
      }
      setGeneratedButton(false);
    }
  }

  React.useEffect(() => {
    setLoading(true);
    getDailyReportingDeclaration(1).then((snapshot) => {
      const page = Math.ceil(snapshot['hydra:totalItems'] / 30);
      setPageCount(page);
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    getDailyReportingDeclaration(page).then((data) => {
      setData(data['hydra:member']);
      setLoading(false);
    });
  }, [page, count]);

  React.useEffect(() => {
    setLoading(true);
    let x = setInterval(() => {
      getDailyReportingDeclaration(page).then((data) => {
        setData(data['hydra:member']);
        setLoading(false);
      });
    }, 10000);

    return () => clearInterval(x);
  }, [page, count]);

  function getDatesInRange(startDate, endDate) {
    return eachDayOfInterval({
      start: new Date(startDate),
      end: new Date(endDate),
    }).map((d) => format(d, 'dd.MM.yyyy'));
  }

  async function startRobot(type) {
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 3000);

    const list_dates =
      type === 'import'
        ? getDatesInRange(rangeImport.from, rangeImport.to)
        : getDatesInRange(rangeExport.from, rangeExport.to);

    try {
      await startDailyRobot(list_dates, type);
      setCount((number) => number + 1);
    } catch {
      alert('Something went wrong!');
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CustomCalendar
          range={rangeImport}
          setRange={setRangeImport}
          title={'Start Procesare Declarații Import'}
          isDisabled={isDisabled}
          onClick={() => startRobot('import')}
        />
        <CustomCalendar
          range={rangeExport}
          setRange={setRangeExport}
          title={'Start Procesare Declarații Export'}
          isDisabled={isDisabled}
          onClick={() => startRobot('export')}
        />
      </Box>

      <GridContainer justify="center">
        <Card>
          <Table
            className="table-striped"
            style={{ overflowX: 'auto' }}
            columns={columns}
            loading={loading}
            bordered
            rowKey={(snap) => {
              return snap.id;
            }}
            dataSource={data}
            showSizeChanger={false}
            pagination={{
              total: 30 * pageCount,
              itemRender: itemRender,
              pageSize: 30,
              onChange: (page) => setPage(page),
            }}
          />
        </Card>
      </GridContainer>
    </>
  );
}
