import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import cx from 'classnames';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

// material-ui icons
import Menu from '@material-ui/icons/Menu';
import MoreVert from '@material-ui/icons/MoreVert';
import ViewList from '@material-ui/icons/ViewList';

// core components
import AdminNavbarLinks from './AdminNavbarLinks';
import Button from 'components/CustomButtons/Button.js';

import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js';
import { getAccounts } from 'services/childrenForClients';
import { updateUserAccount } from 'services/childrenForClients';
import { getActiveAccount } from 'services/childrenForClients';

const useStyles = makeStyles(styles);

export default function AdminNavbar(props) {
  const [activeClient, setActiveClient] = React.useState('');
  const [allClients, setAllClients] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getClients();
  }, []);

  async function setClient(e) {
    setLoading(true);
    const setter_id_child = e.target.value;

    const user = await getActiveAccount(setter_id_child);
    localStorage.setItem('roles', user.roles);
    localStorage.setItem('user', user.displayName);
    localStorage.setItem('RAVEN_CHOSEN_ACCOUNT', user.id);
    setActiveClient(user.id);
    if (user.roles.includes('ROLE_CREATE_DECLARATION')) {
      props.history.push('/admin/listDeclaration', {
        user: user.displayName,
      });
    } else if (user.roles.includes('ROLE_PLEXUS_REPORTING')) {
      props.history.push('/admin/listReporting', { user: user.displayName });
    } else if (user.roles.includes('ROLE_DAILY_REPORTING')) {
      props.history.push('/admin/dailyReports', { user: user.displayName });
    }
    window.location.reload();

    setLoading(false);
  }

  async function getClients() {
    const req = await getAccounts();
    const content = req.data;

    const active_client = localStorage.getItem('RAVEN_CHOSEN_ACCOUNT');
    setActiveClient(active_client);

    setAllClients(content);
  }

  const classes = useStyles();
  const { color, rtlActive, brandText } = props;
  const appBarClasses = cx({
    [' ' + classes[color]]: color,
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    ' ' +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button href="#" className={classes.title} color="transparent">
            {brandText}
          </Button>
        </div>

        <div>
          {!loading ? (
            <FormControl variant="standard" sx={{ minWidth: 140 }}>
              <Select
                style={{ marginRight: 15, paddingLeft: 3 }}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={activeClient}
                onChange={(e) => setClient(e)}
                disabled={allClients.length > 1 ? false : true}
              >
                {allClients.map((data, key) => {
                  return (
                    <MenuItem key={key} value={data.id}>
                      {data.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : (
            <div>
              <LinearProgress
                style={{ marginRight: 15, paddingLeft: 3, minWidth: 140 }}
              />
            </div>
          )}
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks rtlActive={rtlActive} />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
};
