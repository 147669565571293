import 'antd/dist/antd.css';

import { makeStyles } from '@material-ui/core/styles';
import { Table } from 'antd';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import Card from 'components/Card/Card.js';
import GridContainer from 'components/Grid/GridContainer.js';
import React from 'react';
import { config } from 'services/config.js';
import { getGeneratedReportingDeclaration } from 'services/reportDeclarationTable.js';

import { itemRender } from '../../paginationfunction';

const useStyles = makeStyles(styles);

export default function GeneratedRaports() {
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [count, setCount] = React.useState(0);

  const classes = useStyles();

  const columns = [
    {
      title: 'ID',
      render: (text) => <p>{text['id']}</p>,
    },
    {
      title: 'DAY',
      render: (text) => <p>{text['day']}</p>,
    },
    {
      title: 'ACTION',
      render: (text) => (
        <div
          style={{
            backgroundColor: 'orange',
            display: 'inline-flex',
            alignItems: 'center',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 9,
            paddingBottom: 9,
            borderRadius: 4,
            cursor: 'pointer',
            marginTop: 5,
            marginBottom: 5,
          }}
          onClick={() => downloadCsv(text['path'])}
        >
          <span
            style={{
              color: 'white',
              fontWeight: 'bold',
              letterSpacing: 0.75,
            }}
          >
            DOWNLOAD CSV
          </span>
        </div>
      ),
    },
  ];

  function downloadCsv(url) {
    const customUrl = config.apiUrl + url;

    window.open(customUrl, 'Download');
  }

  React.useEffect(() => {
    setLoading(true);
    getGeneratedReportingDeclaration(1).then((snapshot) => {
      const page = Math.ceil(snapshot['hydra:totalItems'] / 30);
      setPageCount(page);
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    getGeneratedReportingDeclaration(page).then((data) => {
      setData(data['hydra:member']);
      setLoading(false);
    });
  }, [page, count]);

  return (
    <GridContainer justify="center">
      <Card>
        <Table
          className="table-striped"
          style={{ overflowX: 'auto' }}
          columns={columns}
          loading={loading}
          bordered
          rowKey={(snap) => {
            return snap.id;
          }}
          dataSource={data}
          showSizeChanger={false}
          pagination={{
            total: 30 * pageCount,
            itemRender: itemRender,
            pageSize: 30,
            onChange: (page) => setPage(page),
          }}
        />
      </Card>
    </GridContainer>
  );
}
