import { config } from './config';

export default async function loginUser(email, password) {
  let url = config.apiUrl + '/login';
  let res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
    }),
  }).then((r) => r.json());
  return res;
}
