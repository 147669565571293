import { Button } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import CountrySelector from 'views/CustomComponents/CountrySelector';
import * as yup from 'yup';

import { TextFormField } from '../CustomComponents/TextFormField';

const formObject = [
  {
    name: 'destinatarDenumire',
    label: 'Destinatar Denumire',
    disabled: false,
    component: TextFormField,
  },
  {
    name: 'destinatarAdresa',
    label: 'Destinatar Adresa',
    disabled: false,
    component: TextFormField,
  },
  {
    name: 'destinatarTara',
    label: 'Destinatar Tara',
    disabled: false,
    placeholder: 'Destinatar Tara',
    component: CountrySelector,
  },
  {
    name: 'destinatarCodPostal',
    label: 'Destinatar Cod Postal',
    disabled: false,
    component: TextFormField,
  },
  {
    name: 'destinatarOras',
    label: 'Destinatar Oras',
    disabled: false,
    component: TextFormField,
  },
  {
    name: 'destinatarCUI',
    label: 'Destinatar CUI',
    disabled: false,
    component: TextFormField,
  },
];

const schema = yup.object({
  destinatarDenumire: yup.string().required().min(1),
  destinatarAdresa: yup.string().required().min(1),
  destinatarTara: yup.string().required().min(1),
  destinatarCodPostal: yup.string().required().min(1),
  destinatarOras: yup.string().required().min(1),
  destinatarCUI: yup.string().required().min(1),
});

export default function SecondPage(props) {
  const handleSubmit = (val) => {
    props.data['destinatarDenumire'] = val.destinatarDenumire;
    props.data['destinatarAdresa'] = val.destinatarAdresa;
    props.data['destinatarTara'] = val.destinatarTara;
    props.data['destinatarCodPostal'] = val.destinatarCodPostal;
    props.data['destinatarOras'] = val.destinatarOras;
    props.data['destinatarCUI'] = val.destinatarCUI;

    props.handleNext();
  };

  return (
    <div>
      <Formik
        validationSchema={schema}
        initialValues={{
          destinatarDenumire: props.data['destinatarDenumire'],
          destinatarAdresa: props.data['destinatarAdresa'],
          destinatarTara: props.data['destinatarTara'],
          destinatarCodPostal: props.data['destinatarCodPostal'],
          destinatarOras: props.data['destinatarOras'],
          destinatarCUI: props.data['destinatarCUI'],
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {() => (
          <div style={{ marginTop: 60 }}>
            <Form>
              {formObject.map((snapshot, key) => {
                return <Field {...snapshot} key={key} />;
              })}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignSelf: 'center',
                  alignItems: 'center',
                  marginTop: 60,
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    width: '49%',
                    padding: 10,
                    color: 'grey',
                    fontWeight: '700',
                  }}
                  onClick={() => props.handleBack()}
                >
                  BACK
                </Button>
                <Button
                  variant="contained"
                  style={{
                    width: '49%',
                    padding: 10,
                    backgroundColor: 'rgb(0, 153, 159)',
                    color: 'white',
                    fontWeight: '700',
                  }}
                  type="submit"
                >
                  NEXT
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}
