import React from 'react';
import { TextFormField } from '../CustomComponents/TextFormField';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { Button, TextField } from '@material-ui/core';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import { DropzoneArea } from 'material-ui-dropzone';
import MailOutline from '@material-ui/icons/MailOutline';
import { makeStyles } from '@material-ui/core/styles';
import Datetime from 'react-datetime';
import { newDeclaration } from '../../services/declarationService.js';
import FormControl from '@material-ui/core/FormControl';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import BarLoader from 'react-spinners/BarLoader';
import { css } from '@emotion/react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { getTaxCodeML } from 'services/machineLearning';

const useStyles = makeStyles(styles);

const override = css`
  display: block;
  margin: 0 auto;
  border-color: gray;
`;

const formObject = [
  {
    name: 'LRN',
    label: 'LRN',
    disabled: false,
    component: TextFormField,
  },
  // {
  //   name: 'Client',
  //   label: 'Client',
  //   disabled: false,
  //   component: TextFormField,
  // },
  // {
  //   name: 'Transport',
  //   label: 'Transport',
  //   disabled: false,
  //   component: TextFormField,
  //},
];

const schema = yup.object({
  LRN: yup.string().required().min(3),
  // Client: yup.string().required().min(3),
  // Transport: yup.string().required().min(3),
});

export default function FirstPage(props) {
  const [error, setError] = React.useState(false);
  const [errorDescription, setErrorDescription] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [loadingML, setLoadingML] = React.useState(false);
  const [responseML, setResponseML] = React.useState('');
  const [checkUnitType, setCheckUnitType] = React.useState(true);
  const [checkRequiredDocs, setCheckRequiredDocs] = React.useState(true);
  const [pn, setPn] = React.useState('');

  const takeTaxCode = async () => {
    const pn_list = [];
    pn_list.push({ external_id: 1, text: pn });
    setLoadingML(true);
    const data = await getTaxCodeML(pn_list);
    if (data.status === 'OK') {
      setResponseML(JSON.parse(data.data));
    } else {
      setResponseML('Error');
    }
    setLoadingML(false);
  };

  const handleDate = (newDate) => {
    props.setDate(newDate);
  };

  const handleUpload = (files) => {
    props.setFiles(files);
  };

  const handleSubmit = (val) => {
    if (!props.files[0]) {
      setError(true);
      setErrorDescription('Please upload CSV!');
      setTimeout(() => {
        setError(false);
        setErrorDescription('');
      }, [3000]);
    } else if (!props.date) {
      setError(true);
      setErrorDescription('Please enter invoice date!');
      setTimeout(() => {
        setError(false);
        setErrorDescription('');
      }, [3000]);
    } else {
      setLoading(true);
      props.setData(val);

      let columns = [
        'DESCRIPTION',
        'TAX_CODE',
        'QTY',
        'NET_WEIGHT',
        'GROSS_WEIGHT',
        'PACKAGE_QTY',
        'ORIGIN',
        'PREF_AREA',
        'UNIT_QTY',
        'UNIT',
        'Preț vânzare (ron)',
        'VALUE',
        'CURRENCY',
        'UNIT_TYPE',
        'PREF',
        'REGIM',
        'REGIM_TYPE',
        'NICE_DESCRIPTION',
        'ADDITIONAL_CODE',
        'BRAND',
        'DESTINATAR',
        'EXPEDITOR',
        'RESPONSABIL_FINANCIAR',
        'DOC_TYPE1',
        'DOC_REF1',
        'DOC_DATE1',
        'DOC_TYPE2',
        'DOC_REF2',
        'DOC_DATE2',
        'DOC_TYPE3',
        'DOC_REF3',
        'DOC_DATE3',
        'DOC_TYPE4',
        'DOC_REF4',
        'DOC_DATE4',
        'DOC_TYPE5',
        'DOC_REF5',
        'DOC_DATE5',
        'DOC_TYPE6',
        'DOC_REF6',
        'DOC_DATE6',
        'DOC_TYPE7',
        'DOC_REF7',
        'DOC_DATE7',
        'DOC_TYPE8',
        'DOC_REF8',
        'DOC_DATE8',
        'DOC_TYPE9',
        'DOC_REF9',
        'DOC_DATE9',
        'DOC_TYPE10',
        'DOC_REF10',
        'DOC_DATE10',
        'DECLARATION_DOC_TYPE1',
        'DECLARATION_DOC_REF1',
        'DECLARATION_DOC_DATE1',
        'DECLARATION_DOC_TYPE2',
        'DECLARATION_DOC_REF2',
        'DECLARATION_DOC_DATE2',
        'DECLARATION_DOC_TYPE3',
        'DECLARATION_DOC_REF3',
        'DECLARATION_DOC_DATE3',
        'DECLARATION_DOC_TYPE',
        'DECLARATION_DOC_REF4',
        'DECLARATION_DOC_DATE4',
        'DECLARATION_DOC_TYPE5',
        'DECLARATION_DOC_REF5',
        'DECLARATION_DOC_DATE5',
        'PREVIOUS_DOC_TYPE',
        'PREVIOUS_DOC_REF',
      ];
      newDeclaration(
        props.files[0],
        columns,
        val.LRN,
        '',
        '',
        checkUnitType,
        checkRequiredDocs
      ).then((snapshot) => {
        if (snapshot.ok) {
          snapshot
            .json()
            .then((response) => {
              props.setDeclarationId(response.declaration.id);
              props.setPath(response.filePath);
              props.handleNext();
            })
            .then(() => {
              setLoading(false);
            });
        } else {
          snapshot.json().then((response) => {
            setLoading(false);
            setError(true);
            setErrorDescription(response['hydra:description']);
            setTimeout(() => {
              setError(false);
              setErrorDescription('');
            }, [7000]);
          });
        }
      });
    }
  };

  const classes = useStyles();

  return (
    <div>
      {loading ? (
        <div style={{ marginTop: 50 }}>
          <BarLoader
            color={'#787878'}
            loading={true}
            css={override}
            size={150}
          />
        </div>
      ) : (
        <Formik
          validationSchema={schema}
          initialValues={{
            LRN: props.data.LRN,
            // Client: props.data.Client,
            // Transport: props.data.Transport,
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {() => (
            <div style={{ marginTop: 60 }}>
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <MailOutline />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Upload CSV</h4>
                </CardHeader>
                <CardBody>
                  <form>
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        onChange={handleDate}
                        closeOnSelect
                        dateFormat="DD.MM.YYYY"
                        inputProps={{ placeholder: 'Data facturii' }}
                      />
                    </FormControl>
                    <DropzoneArea
                      className={classes.fileUpload}
                      showPreviews={true}
                      showPreviewsInDropzone={false}
                      useChipsForPreview
                      filesLimit={1}
                      previewGridProps={{
                        container: { spacing: 1, direction: 'row' },
                      }}
                      previewText="Selected files"
                      onChange={handleUpload}
                    />
                  </form>
                </CardBody>
              </Card>

              <TextField
                style={{ width: '100%', marginTop: 30 }}
                id="outlined-basic"
                variant="outlined"
                onChange={(val) => setPn(val.target.value)}
                placeholder="Product name"
              />

              {loadingML ? (
                <div style={{ marginTop: 20 }}>
                  <BarLoader
                    color={'#787878'}
                    loading={true}
                    css={override}
                    size={100}
                  />
                </div>
              ) : (
                <div style={{ marginTop: 20 }}>
                  <pre>
                    {responseML !== '' && JSON.stringify(responseML, null, 2)}
                  </pre>
                </div>
              )}

              <Button
                variant="contained"
                style={{
                  width: '20%',
                  padding: 10,
                  marginTop: 20,
                  backgroundColor: loadingML
                    ? 'rgba(0,153,159, 0.3)'
                    : 'rgb(0, 153, 159)',
                  color: 'white',
                  fontWeight: '700',
                }}
                disabled={loadingML}
                onClick={() => takeTaxCode()}
              >
                SUBMIT
              </Button>

              {error ? (
                <p style={{ marginTop: 30, color: 'red' }}>
                  {errorDescription}
                </p>
              ) : null}

              <Form>
                {formObject.map((snapshot, key) => {
                  return <Field {...snapshot} key={key} />;
                })}

                <FormGroup style={{ marginTop: 30 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkUnitType}
                        onChange={(val) => setCheckUnitType(val.target.checked)}
                      />
                    }
                    style={{ color: 'black' }}
                    label="Check unit type in csv"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkRequiredDocs}
                        onChange={(val) =>
                          setCheckRequiredDocs(val.target.checked)
                        }
                      />
                    }
                    style={{ color: 'black' }}
                    label="Check required documents in csv"
                  />
                </FormGroup>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignSelf: 'center',
                    alignItems: 'center',
                    marginTop: 60,
                  }}
                >
                  <Button
                    disabled={true}
                    style={{
                      width: '49%',
                      padding: 10,
                      color: 'grey',
                      fontWeight: '700',
                    }}
                  >
                    BACK
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      width: '49%',
                      padding: 10,
                      backgroundColor: 'rgb(0, 153, 159)',
                      color: 'white',
                      fontWeight: '700',
                    }}
                    type="submit"
                  >
                    NEXT
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
}

