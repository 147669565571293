import { config, authHeaders } from './config';

export async function newDeclaration(
  file,
  columns,
  lrn,
  idTemplate,
  documents,
  checkUnitType,
  checkRequiredDocs
) {
  const URL = config.apiUrl + '/bulk_upload';
  const accountId = localStorage.getItem('RAVEN_CHOSEN_ACCOUNT');
  let data = new FormData();
  data.append('file', file);
  data.append('column_mapping', JSON.stringify(columns));

  data.append('lrn', lrn);
  data.append('idTemplate', idTemplate);
  data.append('documents', JSON.stringify(documents));

  data.append('check_unit_type', checkUnitType);
  data.append('check_required_docs', checkRequiredDocs);

  data.append('accountId', accountId);

  return fetch(URL, {
    method: 'POST',
    headers: authHeaders(),
    body: data,
  });
}

export function startRobot(id) {
  const URL = config.apiUrl + '/queueJob';
  const accountId = localStorage.getItem('RAVEN_CHOSEN_ACCOUNT');
  return fetch(URL, {
    method: 'POST',
    headers: authHeaders(),
    body: JSON.stringify({
      declarationId: id,
      type: 'export',
      reportingDeclarationId: 0,
      accountId: accountId,
    }),
  });
}

export function restartRobotDaily(id, type) {
  const userURL = `${config.apiUrl}/users/me`;

  let completeData = fetch(userURL, {
    method: 'GET',
    headers: authHeaders(),
  }).then((a) => {
    if (a.status === 401) window.location.href = '/auth/login';

    return a.text().then(() => {
      const URL = `${config.apiUrl}/daily_reporting_declarations/${id}`;

      const options = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('authToken'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type,
        }),
      };

      return fetch(URL, options);
    });
  });

  return completeData;
}

export function generateDailyPath(id, type) {
  const userURL = `${config.apiUrl}/users/me`;

  let completeData = fetch(userURL, {
    method: 'GET',
    headers: authHeaders(),
  }).then((a) => {
    if (a.status === 401) window.location.href = '/auth/login';

    return a.text().then(() => {
      const URL = `${config.apiUrl}/generate_daily_csv/${id}`;

      const options = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('authToken'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type,
        }),
      };

      return fetch(URL, options);
    });
  });

  return completeData;
}

export function startDailyRobot(list_dates, type) {
  const userURL = `${config.apiUrl}/users/me`;

  let completeData = fetch(userURL, {
    method: 'GET',
    headers: authHeaders(),
  }).then((a) => {
    if (a.status === 401) window.location.href = '/auth/login';

    return a.text().then(() => {
      const URL = `${config.apiUrl}/daily_reporting_declarations`;
      const accountId = localStorage.getItem('RAVEN_CHOSEN_ACCOUNT');
      const options = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('authToken'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          days: list_dates,
          account: `${accountId}`,
          type: type,
        }),
      };

      return fetch(URL, options);
    });
  });

  return completeData;
}

export function replaceDeclarationCSV(file, date, declarationId) {
  let columns = [
    'DESCRIPTION',
    'TAX_CODE',
    'QTY',
    'NET_WEIGHT',
    'GROSS_WEIGHT',
    'PACKAGE_QTY',
    'ORIGIN',
    'PREF_AREA',
    'UNIT_QTY',
    'UNIT',
    'Preț vânzare (ron)',
    'VALUE',
    'CURRENCY',
    'UNIT_TYPE',
    'PREF',
    'REGIM',
    'REGIM_TYPE',
    'NICE_DESCRIPTION',
    'ADDITIONAL_CODE',
    'BRAND',
    'DESTINATAR',
    'EXPEDITOR',
    'RESPONSABIL_FINANCIAR',
    'DOC_TYPE1',
    'DOC_REF1',
    'DOC_DATE1',
    'DOC_TYPE2',
    'DOC_REF2',
    'DOC_DATE2',
    'DOC_TYPE3',
    'DOC_REF3',
    'DOC_DATE3',
    'DOC_TYPE4',
    'DOC_REF4',
    'DOC_DATE4',
    'DOC_TYPE5',
    'DOC_REF5',
    'DOC_DATE5',
    'DOC_TYPE6',
    'DOC_REF6',
    'DOC_DATE6',
    'DOC_TYPE7',
    'DOC_REF7',
    'DOC_DATE7',
    'DOC_TYPE8',
    'DOC_REF8',
    'DOC_DATE8',
    'DOC_TYPE9',
    'DOC_REF9',
    'DOC_DATE9',
    'DOC_TYPE10',
    'DOC_REF10',
    'DOC_DATE10',
    'DECLARATION_DOC_TYPE1',
    'DECLARATION_DOC_REF1',
    'DECLARATION_DOC_DATE1',
    'DECLARATION_DOC_TYPE2',
    'DECLARATION_DOC_REF2',
    'DECLARATION_DOC_DATE2',
    'DECLARATION_DOC_TYPE3',
    'DECLARATION_DOC_REF3',
    'DECLARATION_DOC_DATE3',
    'DECLARATION_DOC_TYPE',
    'DECLARATION_DOC_REF4',
    'DECLARATION_DOC_DATE4',
    'DECLARATION_DOC_TYPE5',
    'DECLARATION_DOC_REF5',
    'DECLARATION_DOC_DATE5',
    'PREVIOUS_DOC_TYPE',
    'PREVIOUS_DOC_REF',
  ];

  const URL = config.apiUrl + '/replaceFile';

  let data = new FormData();
  data.append('file', file);
  data.append('column_mapping', JSON.stringify(columns));
  data.append('date', date.format('M/D/YYYY'));
  data.append('declarationId', declarationId);

  return fetch(URL, {
    method: 'POST',
    headers: authHeaders(),
    body: data,
  });
}
