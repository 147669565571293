import { getIn } from 'formik';
import React from 'react';
import { TextField } from '@material-ui/core';

export const TextFormField = ({ field, form, ...props }) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);
  return (
    <TextField
      style={{ width: '100%', marginTop: 30 }}
      id="outlined-basic"
      variant="outlined"
      helperText={errorText}
      error={!!errorText}
      {...field}
      {...props}
    />
  );
};
