export const config = {
  apiUrl: 'https://api.iraven.ro',
};

// export const config = {
//   apiUrl: 'https://localhost:8000',
// };

export const authHeaders = () => {
  return {
    Authorization: 'Bearer ' + localStorage.getItem('authToken'),
  };
};
