import { Button } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import CountrySelector from 'views/CustomComponents/CountrySelector';
import * as yup from 'yup';

import { TextFormField } from '../CustomComponents/TextFormField';

const formObject = [
  // {
  //   name: 'taraTranzac',
  //   label: 'Tara Tranzactionare',
  //   disabled: false,
  //   placeholder: 'Tara Tranzactionare',
  //   component: CountrySelector,
  // },
  // {
  //   name: 'taraDestinatie',
  //   label: 'Tara Destinatie',
  //   disabled: false,
  //   placeholder: 'Tara Destinatie',
  //   component: CountrySelector,
  // },
  // {
  //   name: 'conditiiLivrareTermen',
  //   label: 'Conditii Livrare Termen',
  //   disabled: false,
  //   component: TextFormField,
  // },
  // {
  //   name: 'conditiiLivrareOras',
  //   label: 'Conditii Livrare Oras',
  //   disabled: false,
  //   component: TextFormField,
  // },
  // {
  //   name: 'conditiiLivrareLimba',
  //   label: 'Conditii Livrare Limba',
  //   disabled: false,
  //   component: TextFormField,
  // },
  {
    name: 'birouDeIesire',
    label: 'Birou Iesire',
    disabled: false,
    component: TextFormField,
  },
];

const schema = yup.object({
  // taraTranzac: yup.string().required().min(1),
  // taraDestinatie: yup.string().required().min(1),
  // conditiiLivrareTermen: yup.string().required().min(1),
  // conditiiLivrareOras: yup.string().required().min(1),
  // conditiiLivrareLimba: yup.string().required().min(1),
  birouDeIesire: yup.string().required().min(1),
});

export default function FourthPage(props) {
  const handleSubmit = (val) => {
    // props.data['taraTranzac'] = val.taraTranzac;
    // props.data['taraDestinatie'] = val.taraDestinatie;
    // props.data['conditiiLivrareTermen'] = val.conditiiLivrareTermen;
    // props.data['conditiiLivrareOras'] = val.conditiiLivrareOras;
    // props.data['conditiiLivrareLimba'] = val.conditiiLivrareLimba;
    props.data['birouDeIesire'] = val.birouDeIesire;

    props.handleNext();
  };

  return (
    <div>
      <Formik
        validationSchema={schema}
        initialValues={{
          // taraTranzac: props.data['taraTranzac'],
          // taraDestinatie: props.data['taraDestinatie'],
          // conditiiLivrareTermen: props.data['conditiiLivrareTermen'],
          // conditiiLivrareOras: props.data['conditiiLivrareOras'],
          // conditiiLivrareLimba: props.data['conditiiLivrareLimba'],
          birouDeIesire: props.data['birouDeIesire'],
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {() => (
          <div style={{ marginTop: 60 }}>
            <Form>
              {formObject.map((snapshot, key) => {
                return <Field {...snapshot} key={key} />;
              })}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignSelf: 'center',
                  alignItems: 'center',
                  marginTop: 60,
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    width: '49%',
                    padding: 10,
                    color: 'grey',
                    fontWeight: '700',
                  }}
                  onClick={() => props.handleBack()}
                >
                  BACK
                </Button>
                <Button
                  variant="contained"
                  style={{
                    width: '49%',
                    padding: 10,
                    backgroundColor: 'rgb(0, 153, 159)',
                    color: 'white',
                    fontWeight: '700',
                  }}
                  type="submit"
                >
                  NEXT
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}
