import { Button } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import Card from 'components/Card/Card.js';
import * as yup from 'yup';
import { TextField } from '@material-ui/core';

import { TextFormField } from '../CustomComponents/TextFormField';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import CountrySelector from 'views/CustomComponents/CountrySelector';

const formObject = [
  {
    name: 'templateDenumire',
    label: 'Nume Template',
    disabled: false,
    component: TextFormField,
  },
  {
    name: 'nrDeclarant',
    label: 'Numar Declarant',
    disabled: false,
    component: TextFormField,
  },
  {
    name: 'codTaraOrigine',
    label: 'Cod Tara Origine',
    placeholder: 'Cod Tara Origine',
    disabled: false,
    component: CountrySelector,
  },
  {
    name: 'preferinta',
    label: 'Preferinta',
    disabled: false,
    component: TextFormField,
  },
  {
    name: 'regim1',
    label: 'Regim (1)',
    disabled: false,
    component: TextFormField,
  },
  {
    name: 'regim2',
    label: 'Regim (2)',
    disabled: false,
    component: TextFormField,
  },
  // DOCUMENTE ATASATE
];

const schema = yup.object({
  templateDenumire: yup.string().required().min(1),
  nrDeclarant: yup.string().required().min(1),
  codTaraOrigine: yup.string().required().min(1),
  preferinta: yup.string().required().min(1),
  regim1: yup.string().required().min(1),
  regim2: yup.string().required().min(1),
});

const today = moment();

export default function FifthPage(props) {
  const [data, setData] = React.useState([]);
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: false,
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorDescription, setErrorDescription] = React.useState('');
  const [field, setField] = React.useState({
    refDoc: '',
    tipDoc: '',
    dataDoc: moment(today).format('YYYY-MM-DD'),
    limbaDoc: '',
  });
  const columns = [
    {
      title: 'TIP DOCUMENT',
      render: (text) => <p>{text['TIP DOCUMENT']}</p>,
    },
    {
      title: 'REF DOCUMENT',
      render: (text) => <p>{text['REF DOCUMENT']}</p>,
    },
    {
      title: 'REF DOCUMENT EDITABIL',
      render: (text) => <p>{text['REF DOCUMENT EDITABIL']}</p>,
    },
    {
      title: 'DATA',
      render: (text) => <p>{text['DATA']}</p>,
    },
    {
      title: 'LIMBA',
      render: (text) => <p>{text['LIMBA']}</p>,
    },
    {
      title: 'ACTION',
      render: (text) => (
        <divd
          style={{
            backgroundColor: '#ed616d',
            display: 'inline-flex',
            alignItems: 'center',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 9,
            paddingBottom: 9,
            borderRadius: 4,
            cursor: 'pointer',
          }}
          onClick={() => removeRow(text['TIP DOCUMENT'])}
        >
          <span
            style={{
              color: 'white',
              fontWeight: 'bold',
              letterSpacing: 0.75,
            }}
          >
            DELETE
          </span>
        </divd>
      ),
    },
  ];

  function removeRow(tipDoc) {
    const newList = data.filter((item) => item['TIP DOCUMENT'] !== tipDoc);

    setData(newList);
  }

  const handleSubmit = (val) => {
    props.data['templateDenumire'] = val.templateDenumire;
    props.data['nrDeclarant'] = val.nrDeclarant;
    props.data['documenteAtasate'] = data;

    props.data['codTaraOrigine'] = val.codTaraOrigine;
    props.data['preferinta'] = val.preferinta;
    props.data['regim1'] = val.regim1;
    props.data['regim2'] = val.regim2;

    props.handleNext();
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const changeField = (event) => {
    setField({ ...field, [event.target.name]: event.target.value });
  };

  function resetFields() {
    setField({
      refDoc: '',
      tipDoc: '',
      dataDoc: moment(today).format('YYYY-MM-DD'),
      limbaDoc: '',
    });
  }

  function addNewRow() {
    if (field.tipDoc && field.refDoc && field.limbaDoc) {
      const object = {
        'TIP DOCUMENT': field.tipDoc,
        'REF DOCUMENT': field.refDoc,
        'REF DOCUMENT EDITABIL': state.checkedA ? 'True' : 'False',
        DATA: state.checkedB ? 'Data din factura' : field.dataDoc,
        LIMBA: field.limbaDoc,
      };

      setData([...data, object]);
      setField({
        refDoc: '',
        tipDoc: '',
        dataDoc: moment(today).format('YYYY-MM-DD'),
        limbaDoc: '',
      });
    } else {
      setError(true);
      setErrorDescription('Completeaza toate campurile!');
      setTimeout(() => {
        setError(false);
        setErrorDescription('');
      }, [3000]);
    }
  }

  return (
    <div>
      <Formik
        validationSchema={schema}
        initialValues={{
          templateDenumire: props.data['templateDenumire'],
          nrDeclarant: props.data['nrDeclarant'],
          documenteAtasate: props.data['documenteAtasate'],
          codTaraOrigine: props.data['codTaraOrigine'],
          preferinta: props.data['preferinta'],
          regim1: props.data['regim1'],
          regim2: props.data['regim2'],
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {() => (
          <div style={{ marginTop: 60 }}>
            <Form>
              {formObject.map((snapshot, key) => {
                return <Field {...snapshot} key={key} />;
              })}
              <Card>
                <Table
                  className="table-striped"
                  style={{ overflowX: 'auto' }}
                  columns={columns}
                  bordered
                  rowKey={(snap) => {
                    return snap['TIP DOCUMENT'];
                  }}
                  pagination={false}
                  dataSource={data}
                />
              </Card>
              <Card>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <TextField
                    style={{ margin: 10, width: '33%' }}
                    variant="outlined"
                    label="TIP DOC."
                    name="tipDoc"
                    value={field.tipDoc}
                    onChange={changeField}
                  />
                  <TextField
                    style={{ margin: 10, width: '33%' }}
                    variant="outlined"
                    label="REF. DOC."
                    name="refDoc"
                    value={field.refDoc}
                    onChange={changeField}
                  />

                  <TextField
                    style={{ margin: 10, width: '33%' }}
                    variant="outlined"
                    label="LIMBA"
                    value={field.limbaDoc}
                    name="limbaDoc"
                    onChange={changeField}
                  />
                </div>
                {!state.checkedB && (
                  <div>
                    <TextField
                      style={{ marginLeft: 10, marginTop: 10, width: '33%' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      variant="outlined"
                      label="DATA"
                      value={field.dataDoc}
                      name="dataDoc"
                      onChange={changeField}
                    />
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 15,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedA}
                        onChange={handleChange}
                        name="checkedA"
                        color="primary"
                      />
                    }
                    label="Referinta documentului poate sa fie editabil"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={handleChange}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Data va veni din factura"
                  />
                </div>
                <div style={{ margin: 15 }}>
                  {error ? (
                    <p style={{ color: 'red' }}>{errorDescription}</p>
                  ) : null}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    margin: 15,
                  }}
                >
                  <Button
                    variant="contained"
                    style={{
                      width: '20%',
                      padding: 10,
                      color: 'grey',
                      fontWeight: '700',
                      marginRight: 15,
                    }}
                    onClick={resetFields}
                  >
                    RESET
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      width: '20%',
                      padding: 10,
                      backgroundColor: 'rgb(0, 153, 159)',
                      color: 'white',
                      fontWeight: '700',
                    }}
                    onClick={addNewRow}
                  >
                    ADD NEW ROW
                  </Button>
                </div>
              </Card>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignSelf: 'center',
                  alignItems: 'center',
                  marginTop: 60,
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    width: '49%',
                    padding: 10,
                    color: 'grey',
                    fontWeight: '700',
                  }}
                  onClick={() => props.handleBack()}
                >
                  BACK
                </Button>
                <Button
                  variant="contained"
                  style={{
                    width: '49%',
                    padding: 10,
                    backgroundColor: 'rgb(0, 153, 159)',
                    color: 'white',
                    fontWeight: '700',
                  }}
                  type="submit"
                >
                  NEXT
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}
