import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Label,
  Container,
  Col,
} from 'reactstrap';

function ForgotPasword(props) {
  return (
    <div className="lock-page">
      <Container>
        <Col className="ml-auto mr-auto" lg="4" md="6">
          <Card className="card-lock text-center">
            <CardHeader>
              <img alt="..." src={require('assets/img/lock.jpeg')} />
              <h3 className="header text-center">Forgot password</h3>
            </CardHeader>
            <CardBody>
              <FormGroup>
                <div style={{ marginBottom: '20px' }}>
                  Please enter your email address below so we can send you a
                  link to reset your password
                </div>
                <Input
                  placeholder="Enter email.."
                  type="email"
                  autoComplete="off"
                />
              </FormGroup>
            </CardBody>
            <CardFooter>
              <Button
                className="btn-round"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                outline
              >
                Send link
              </Button>
              <Label style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={() => props.history.push('/auth/login')}
                  className="btn-link"
                >
                  Go back
                </Button>
              </Label>
            </CardFooter>
          </Card>
        </Col>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require('../../assets/img/login.jpeg')})`,
        }}
      />
    </div>
  );
}

export default ForgotPasword;
