import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import AuthLayout from 'layouts/Auth.js';
import AdminLayout from 'layouts/Admin.js';

import 'assets/scss/material-dashboard-pro-react.scss?v=1.9.0';
import 'react-day-picker/dist/style.css';

const hist = createBrowserHistory();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acc1',
    },
  },
  overrides: {
    MuiDropzoneArea: {
      root: { minHeight: '120px', marginTop: '12px', marginBottom: '12px' },
    },
  },
});

ReactDOM.render(
  <Router history={hist}>
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        <Route path="/admin" component={AdminLayout} />
        <Redirect from="/" to="/auth/login" />
      </Switch>
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
);
