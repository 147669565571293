import React from 'react';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import { DropzoneArea } from 'material-ui-dropzone';
import MailOutline from '@material-ui/icons/MailOutline';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import { Button } from '@material-ui/core';
import { replaceDeclarationCSV } from 'services/declarationService';
import BarLoader from 'react-spinners/BarLoader';
import { css } from '@emotion/react';

const useStyles = makeStyles(styles);

const override = css`
  display: block;
  margin: 0 auto;
  border-color: gray;
`;

export default function ThirdPage(props) {
  const [date, setDate] = React.useState();
  const [files, setFiles] = React.useState();
  const [error, setError] = React.useState(false);
  const [errorDescription, setErrorDescription] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleUpload = (files) => {
    setFiles(files);
  };

  const handleDate = (newDate) => {
    setDate(newDate);
  };

  function replaceCsv() {
    if (!files[0] || !date) {
      props.handleNext();
    } else {
      setLoading(true);
      replaceDeclarationCSV(files[0], date, props.declarationId).then(
        (snapshot) => {
          if (snapshot.status < 300) {
            snapshot
              .json()
              .then((response) => {
                props.handleNext();
              })
              .then(() => {
                setLoading(false);
              });
          } else {
            setLoading(false);
            setError(true);
            setErrorDescription(
              'Something went wrong with your CSV, please try again!'
            );
            setTimeout(() => {
              setError(false);
              setErrorDescription('');
            }, [3000]);
          }
        }
      );
    }
  }

  const classes = useStyles();

  return (
    <div>
      {loading ? (
        <div style={{ marginTop: 50 }}>
          <BarLoader
            color={'#787878'}
            loading={true}
            css={override}
            size={150}
          />
        </div>
      ) : (
        <div>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Upload new CSV</h4>
            </CardHeader>
            <CardBody>
              <form>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    onChange={handleDate}
                    closeOnSelect
                    dateFormat="DD.MM.YYYY"
                    inputProps={{ placeholder: 'Data facturii' }}
                  />
                </FormControl>
                <DropzoneArea
                  className={classes.fileUpload}
                  showPreviews={true}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  filesLimit={1}
                  previewGridProps={{
                    container: { spacing: 1, direction: 'row' },
                  }}
                  previewText="Selected files"
                  onChange={handleUpload}
                />
              </form>
            </CardBody>
          </Card>

          {error ? (
            <p style={{ marginTop: 30, color: 'red' }}>{errorDescription}</p>
          ) : null}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignSelf: 'center',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <Button
              variant="contained"
              style={{
                width: '49%',
                padding: 10,
                color: 'grey',
                fontWeight: '700',
              }}
              onClick={() => props.handleBack()}
            >
              BACK
            </Button>
            <Button
              variant="contained"
              style={{
                width: '49%',
                padding: 10,
                backgroundColor: 'rgb(0, 153, 159)',
                color: 'white',
                fontWeight: '700',
              }}
              onClick={() => replaceCsv()}
            >
              NEXT OR SKIP
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
