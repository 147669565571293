import React from 'react';
import ro from 'date-fns/locale/ro';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import CalendarButton from './CalendarButton';

export default function CustomCalendar({
  range,
  setRange,
  title,
  isDisabled,
  onClick,
}) {
  //TODO: documentatie https://react-day-picker.js.org/basics/modifiers
  let footer = (
    <p style={{ marginTop: '1rem', fontWeight: '500' }}>
      Alegeți ziua de start
    </p>
  );

  if (range?.from) {
    if (!range?.to) {
      footer = (
        <p style={{ marginTop: '1rem' }}>
          Perioada aleasă:{' '}
          <span style={{ fontWeight: '500' }}>
            {format(range.from, 'dd.MM.yyyy')}
          </span>
        </p>
      );
    } else if (range?.to) {
      footer = (
        <p style={{ marginTop: '1rem' }}>
          Perioada aleasă:{' '}
          <span style={{ fontWeight: '500' }}>
            {' '}
            {format(range.from, 'dd.MM.yyyy')} –{' '}
            {format(range.to, 'dd.MM.yyyy')}
          </span>
        </p>
      );
    }
  }

  return (
    <div>
      <DayPicker
        mode="range"
        locale={ro}
        selected={range}
        onSelect={setRange}
        toDate={new Date()}
        footer={footer}
        style={{ marginRight: '12rem' }}
      />
      <CalendarButton
        opacity={range?.to ? 1 : 0.6}
        disabled={isDisabled || !range?.to ? true : false}
        isDisabled={isDisabled}
        text={title}
        onClick={onClick}
      />
    </div>
  );
}
