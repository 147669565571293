import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from 'components/CustomButtons/Button.js';

export default function CalendarButton({
  opacity,
  disabled,
  isDisabled,
  onClick,
  text,
}) {
  return (
    <Button
      variant="contained"
      style={{
        width: '300px',
        backgroundColor: '#ffa500',
        color: '#4a4a4a',
        fontWeight: '700',
        borderRadius: 4,
        height: 56,
        marginLeft: '1em',
        opacity: { opacity },
      }}
      disabled={disabled}
      onClick={onClick}
    >
      {isDisabled ? (
        <CircularProgress color="inherit" size={24} />
      ) : (
        <span style={{ fontSize: '0.8rem' }}>{text}</span>
      )}
    </Button>
  );
}
