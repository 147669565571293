import { Button } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import CountrySelector from 'views/CustomComponents/CountrySelector';
import * as yup from 'yup';

import { TextFormField } from '../CustomComponents/TextFormField';

const formObject = [
  {
    name: 'expeditorDenumire',
    label: 'Expeditor Denumire',
    disabled: false,
    component: TextFormField,
  },
  {
    name: 'expeditorAdresa',
    label: 'Expeditor Adresa',
    disabled: false,
    component: TextFormField,
  },
  {
    name: 'expeditorTara',
    label: 'Expeditor Tara',
    disabled: false,
    placeholder: 'Expeditor Tara',
    component: CountrySelector,
  },
  {
    name: 'expeditorCodPostal',
    label: 'Expeditor Cod Postal',
    disabled: false,
    component: TextFormField,
  },
  {
    name: 'expeditorOras',
    label: 'Expeditor Oras',
    disabled: false,
    component: TextFormField,
  },
  {
    name: 'expeditorCUI',
    label: 'Expeditor CUI',
    disabled: false,
    component: TextFormField,
  },
];

const schema = yup.object({
  expeditorDenumire: yup.string().required().min(1),
  expeditorAdresa: yup.string().required().min(1),
  expeditorTara: yup.string().required().min(1),
  expeditorCodPostal: yup.string().required().min(1),
  expeditorOras: yup.string().required().min(1),
  expeditorCUI: yup.string().required().min(1),
});

export default function FirstPage(props) {
  const handleSubmit = (val) => {
    props.data['expeditorDenumire'] = val.expeditorDenumire;
    props.data['expeditorAdresa'] = val.expeditorAdresa;
    props.data['expeditorTara'] = val.expeditorTara;
    props.data['expeditorCodPostal'] = val.expeditorCodPostal;
    props.data['expeditorOras'] = val.expeditorOras;
    props.data['expeditorCUI'] = val.expeditorCUI;

    props.handleNext();
  };

  return (
    <div>
      <Formik
        validationSchema={schema}
        initialValues={{
          expeditorDenumire: props.data['expeditorDenumire'],
          expeditorAdresa: props.data['expeditorAdresa'],
          expeditorTara: props.data['expeditorTara'],
          expeditorCodPostal: props.data['expeditorCodPostal'],
          expeditorOras: props.data['expeditorOras'],
          expeditorCUI: props.data['expeditorCUI'],
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {() => (
          <div style={{ marginTop: 60 }}>
            <Form>
              {formObject.map((snapshot, key) => {
                return <Field {...snapshot} key={key} />;
              })}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignSelf: 'center',
                  alignItems: 'center',
                  marginTop: 60,
                }}
              >
                <Button
                  disabled={true}
                  style={{
                    width: '49%',
                    padding: 10,
                    color: 'grey',
                    fontWeight: '700',
                  }}
                >
                  BACK
                </Button>
                <Button
                  variant="contained"
                  style={{
                    width: '49%',
                    padding: 10,
                    backgroundColor: 'rgb(0, 153, 159)',
                    color: 'white',
                    fontWeight: '700',
                  }}
                  type="submit"
                >
                  NEXT
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}
