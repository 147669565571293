import React from 'react';
import FirstPage from './first_page_template';
import SecondPage from './second_page_template';
import ThirdPage from './third_page_template';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import FourthPage from './fourth_page_template';
import FifthPage from './fifth_page_template';
import FinishPage from './finish_page_template';
import { getCurrentUser } from 'services/template';
import { postTemplate } from 'services/template';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    'Expeditor',
    //'Destinatar',
    'Responsabil Financiar',
    'Birou Iesire',
    'Alte Info',
  ];
}

function getStepContent(
  step,
  handleNext,
  handleBack,
  dataFields,
  setDataFields,
  handleSubmit,
  handleReset
) {
  switch (step) {
    case 0:
      return (
        <FirstPage
          handleNext={handleNext}
          handleBack={handleBack}
          data={dataFields}
          setData={setDataFields}
        />
      );
    // case 1:
    //   return (
    //     <SecondPage
    //       handleNext={handleNext}
    //       handleBack={handleBack}
    //       data={dataFields}
    //       setData={setDataFields}
    //     />
    //   );
    case 1:
      return (
        <ThirdPage
          handleNext={handleNext}
          handleBack={handleBack}
          data={dataFields}
          setData={setDataFields}
        />
      );
    case 2:
      return (
        <FourthPage
          handleNext={handleNext}
          handleBack={handleBack}
          data={dataFields}
          setData={setDataFields}
        />
      );
    case 3:
      return (
        <FifthPage
          handleNext={handleNext}
          handleBack={handleBack}
          data={dataFields}
          setData={setDataFields}
        />
      );
    default:
      return (
        <FinishPage
          handleSubmit={handleSubmit}
          handleReset={handleReset}
          handleBack={handleBack}
          data={dataFields}
          setData={setDataFields}
        />
      );
  }
}

export default function WizardDeclaration() {
  const history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  const [data, setData] = React.useState({
    userId: null,

    templateDenumire: null,

    expeditorDenumire: null,
    expeditorAdresa: null,
    expeditorTara: '',
    expeditorCodPostal: null,
    expeditorOras: null,
    expeditorCUI: null,

    destinatarDenumire: null,
    destinatarAdresa: null,
    destinatarTara: '',
    destinatarCodPostal: null,
    destinatarOras: null,
    destinatarCUI: null,

    responsabilFinanciarDenumire: null,
    responsabilFinanciarAdresa: null,
    responsabilFinanciarTara: '',
    responsabilFinanciarCodPostal: null,
    responsabilFinanciarOras: null,
    responsabilFinanciarCUI: null,

    taraTranzac: '',
    taraDestinatie: '',
    conditiiLivrareTermen: null,
    conditiiLivrareOras: null,
    conditiiLivrareLimba: null,
    birouDeIesire: null,

    nrDeclarant: null,
    codTaraOrigine: '',
    preferinta: null,
    regim1: null,
    regim2: null,

    documenteAtasate: [],
  });

  const handleSubmit = async () => {
    const userReq = await getCurrentUser();
    if (userReq) {
      data['userId'] = `/users/${userReq}`;
      const postResponse = await postTemplate(data);
      if (postResponse.status < 300) {
        history.push('/admin/declarationTemplate');
        setData({
          userId: null,

          templateDenumire: null,

          expeditorDenumire: null,
          expeditorAdresa: null,
          expeditorTara: '',
          expeditorCodPostal: null,
          expeditorOras: null,
          expeditorCUI: null,

          destinatarDenumire: null,
          destinatarAdresa: null,
          destinatarTara: '',
          destinatarCodPostal: null,
          destinatarOras: null,
          destinatarCUI: null,

          responsabilFinanciarDenumire: null,
          responsabilFinanciarAdresa: null,
          responsabilFinanciarTara: '',
          responsabilFinanciarCodPostal: null,
          responsabilFinanciarOras: null,
          responsabilFinanciarCUI: null,

          taraTranzac: '',
          taraDestinatie: '',
          conditiiLivrareTermen: null,
          conditiiLivrareOras: null,
          conditiiLivrareLimba: null,
          birouDeIesire: null,

          nrDeclarant: null,
          codTaraOrigine: '',
          preferinta: null,
          regim1: null,
          regim2: null,

          documenteAtasate: [],
        });
      }
    }
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setData({
      templateDenumire: null,

      expeditorDenumire: null,
      expeditorAdresa: null,
      expeditorTara: '',
      expeditorCodPostal: null,
      expeditorOras: null,
      expeditorCUI: null,

      destinatarDenumire: null,
      destinatarAdresa: null,
      destinatarTara: '',
      destinatarCodPostal: null,
      destinatarOras: null,
      destinatarCUI: null,

      responsabilFinanciarDenumire: null,
      responsabilFinanciarAdresa: null,
      responsabilFinanciarTara: '',
      responsabilFinanciarCodPostal: null,
      responsabilFinanciarOras: null,
      responsabilFinanciarCUI: null,

      taraTranzac: '',
      taraDestinatie: '',

      conditiiLivrareTermen: null,
      conditiiLivrareOras: null,
      conditiiLivrareLimba: null,

      birouDeIesire: null,

      nrDeclarant: null,
      codTaraOrigine: '',
      preferinta: null,
      regim1: null,
      regim2: null,

      documenteAtasate: [],
    });
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <div>
        <Typography className={classes.instructions}>
          {getStepContent(
            activeStep,
            handleNext,
            handleBack,
            data,
            setData,
            handleSubmit,
            handleReset
          )}
        </Typography>
      </div>
    </div>
  );
}
